import get from 'lodash/get';
import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { fields, schema } from './schema';
import Input from '../../../../components/Input';

const BroadcastForm = ({ onSubmit, headerLabel, onRemove, initialValues = {}, submitLabel = 'Save' }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onSubmitForm = useCallback(
    (values) => {
      // If validation was successful, make sure we reset the form
      reset();
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <Box width={'500px'} mt={15} as={'form'} onSubmit={handleSubmit(onSubmitForm)}>
      {headerLabel ? <h3>{headerLabel}</h3> : null}
      {fields.map((field) => {
        return (
          <Input
            key={field.name}
            options={field.options}
            error={get(errors, field.name, false)}
            register={register}
            defaultValue={field.defaultValue}
            hidden={field.hidden}
            readOnly={field.readOnly}
            name={field.name}
            label={field.label}
            placeholder={get(field, 'placeholder')}
          />
        );
      })}
      <Button mt={4} type='submit' isLoading={isSubmitting}>
        {submitLabel}
      </Button>
      {onRemove ? (
        <Button ml={3} mt={4} onClick={onRemove}>
          Remove
        </Button>
      ) : null}
    </Box>
  );
};

export default BroadcastForm;
