import get from 'lodash/get';
import React, { useState, createContext, useCallback } from 'react';

export const FormContext = createContext();

export const FormContextProvider = (props) => {
  const [formValues, setFormValues] = useState({});

  const onPressNext = useCallback(
    (recordType, values) => {
      const newFormValues = { ...formValues, [recordType]: values };
      setFormValues(newFormValues);
      return newFormValues;
    },
    [formValues, setFormValues],
  );

  const getFormValuesByRecordType = useCallback(
    (recordType) => {
      return get(formValues, recordType, {});
    },
    [formValues],
  );

  return (
    <FormContext.Provider value={{ onPressNext, formValues, getFormValuesByRecordType }}>
      {props.children}
    </FormContext.Provider>
  );
};

export default FormContextProvider;
