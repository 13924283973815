import { Box, Flex } from '@chakra-ui/layout';
import bodymovin from 'bodymovin';
import React from 'react';
import data from '../assets/data.json';

export const SambaLoader = () => {
  let animation = null;

  const initialize = (ref) => {
    if (!animation) {
      animation = bodymovin.loadAnimation({
        container: ref,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: data,
      });
    }
  };

  return (
    <Flex align='center' justify='center' w='100vw' h='100vh' position='absolute' top='0' left='0'>
      <Box w='150' h='150' ref={(ref) => initialize(ref)} />
    </Flex>
  );
};
