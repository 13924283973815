const { REACT_APP_AUTH0_CALLBACKURL, REACT_APP_AUTH0_CLIENTID, REACT_APP_AUTH0_DOMAIN } = process.env;

export const getAuth0Domain = () => {
  return REACT_APP_AUTH0_DOMAIN;
};

export const getAuth0ClientId = () => {
  return REACT_APP_AUTH0_CLIENTID;
};

export const getAuth0CallbackUrl = () => {
  return REACT_APP_AUTH0_CALLBACKURL;
};

export const getAuth0RedirectUrl = () => {
  return REACT_APP_AUTH0_CALLBACKURL;
};
