import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/layout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { ORDERED_RECORD_TYPES, schemas, recordTotalFields } from './schema';
import AgencyRecord from './agency/AgencyRecord';
import StationRecord from './station/StationRecord';
import InvoiceHeaderRecord from './invoice-header/InvoiceHeaderRecord';
import InvoiceTotalRecord from './invoice-total/InvoiceTotalRecord';
import BroadcastDetailRecord from './broadcast-detail/BroadcastDetailRecord';
import { buildInvoiceRow } from '../services/invoice-generator';

const Form = () => {
  const [formPage, setFormPage] = useState(0);

  const onSelectIndex = useCallback(
    (index) => {
      setFormPage(index);
    },
    [setFormPage],
  );

  const onPressNext = useCallback(() => {
    setFormPage(formPage + 1);
  }, [formPage, setFormPage]);

  const onPressPrev = useCallback(() => {
    setFormPage(formPage - 1);
  }, [formPage, setFormPage]);

  function onSubmit(newValues) {
    // Use schemas, invoice values, and total fields to build invoice rows
    const rows = _.chain(ORDERED_RECORD_TYPES)
      .map((recordType) => {
        const recordData = _.get(newValues, recordType, {});
        return _.castArray(recordData).map((record) => {
          return buildInvoiceRow(record, schemas[recordType], recordTotalFields[recordType]);
        });
      })
      .flatten()
      .valueOf();

    const textContent = `data:text/plain;charset=utf-8,${rows.join('\n')}`;
    const encodedUri = encodeURI(textContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'my_data.txt');
    document.body.appendChild(link); // Required for FF
    link.click();
    link.remove();
  }
  return (
    <Box pt={25} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box mb={6} width={'800px'}>
        <Tabs selectedIndex={formPage} onSelect={onSelectIndex}>
          <TabList>
            <Tab>Agency</Tab>
            <Tab>Station</Tab>
            <Tab>Invoice Header</Tab>
            <Tab>Broadcast</Tab>
            <Tab>Invoice Total</Tab>
          </TabList>
          <TabPanel>
            <AgencyRecord onPressNext={onPressNext} />
          </TabPanel>
          <TabPanel>
            <StationRecord onPressNext={onPressNext} onPressPrev={onPressPrev} />
          </TabPanel>
          <TabPanel>
            <InvoiceHeaderRecord onPressNext={onPressNext} onPressPrev={onPressPrev} />
          </TabPanel>
          <TabPanel>
            <BroadcastDetailRecord onPressNext={onPressNext} onPressPrev={onPressPrev} />
          </TabPanel>
          <TabPanel>
            <InvoiceTotalRecord onPressNext={onSubmit} onPressPrev={onPressPrev} />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Form;
