import React, { useState, createContext, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useAsyncCallback } from 'react-async-hook';

import Auth0 from '../../services/auth0';

export const UserContext = createContext();
const auth = new Auth0();

export const UserContextProvider = (props) => {
  const [user, setUser] = useState();
  const [userError, setUserError] = useState();

  const {
    loading,
    error,
    execute: loadUserInfo,
  } = useAsyncCallback(async () => {
    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      setUserError(null);
      return;
    }
    auth.auth0.client.userInfo(accessToken, (err, authResults) => {
      if (err) {
        setUserError(err);
        setUser(null);
      } else {
        setUser(authResults);
      }
    });
  });

  const signedIn = useMemo(() => {
    return !!user;
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        loadUserInfo,
        loading,
        error: error || userError,
        user,
        userError,
        signedIn,
        auth,
        setUserError,
        setUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
