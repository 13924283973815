import get from 'lodash/get';
import has from 'lodash/has';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';

export const buildInvoiceRow = (values, fields, maxCount) => {
  // Sort by field position to process in order
  const sortedFields = orderBy(fields, (f) => f.fieldPosition, ['asc']);

  // We need to fill out the entire row, even if we are not entering anything in some of the fields
  const row = range(1, maxCount).map((i) => {
    const fieldAtIndex = find(sortedFields, (f) => f.fieldPosition === i);
    if (fieldAtIndex) {
      // Use default value, if no value provided - the entire form for that invoice row may have been hidden
      const defaultValue = fieldAtIndex.defaultValue || '';
      return has(values, fieldAtIndex.name) ? `${get(values, fieldAtIndex.name, defaultValue)};` : `${defaultValue};`;
    }

    // Use empty field, so we know it was processed and we do not have data
    return ';';
  });
  return row.join('');
};
