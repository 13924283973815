import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, chakra, Flex, GridItem, HStack, Link, SimpleGrid, Tag, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Auth0Callback from './Auth0Callback';
import Signin from './SignIn';
import { Logo, LogoText } from '../../components/Logo';

const UnauthorizedApp = () => {
  return (
    <Box mx='auto'>
      <SimpleGrid alignItems='center' w='full' columns={{ base: 1, xl: 12 }} gap={{ base: 0 }} mx='auto'>
        <GridItem
          bg={useColorModeValue('white', 'gray.900')}
          h='100vh'
          colSpan={{ base: 'auto', xl: 6 }}
          d='flex'
          px={{ base: 5, xl: 10 }}
          flexDirection='column'
          justifyContent='space-between'
          shadow='2xl'
        >
          <Flex align='center' direction='column' pt='20' pb='10'>
            <Link href='/'>
              <HStack spacing={2}>
                <Logo boxSize='10' />
                <LogoText />
              </HStack>
            </Link>
          </Flex>
          <Switch>
            <Route path='/' exact render={() => <Signin />} />
            <Route path='/callback' exact render={() => <Auth0Callback />} />
            <Route component={() => <Redirect to='/' />} />
          </Switch>
          <chakra.p fontSize='xs' textAlign='center' color='gray.600' py={6}>
            © Copyright 2021 |{' '}
            <Link href='https://samba.tv' isExternal>
              samba.tv
            </Link>
          </chakra.p>
        </GridItem>

        <GridItem
          h='100vh'
          px='20'
          flexDirection='column'
          justifyContent='center'
          colSpan={{ base: 'auto', lg: 6 }}
          textAlign={{ base: 'center', lg: 'left' }}
          display={{ base: 'none', xl: 'flex' }}
        >
          <Box pb={8}>
            <Tag>Alpha</Tag>
          </Box>
          <chakra.h1
            mb={4}
            fontSize={{ base: '3xl', md: '4xl' }}
            fontWeight='bold'
            color={useColorModeValue('gray.900', 'gray.200')}
            letterSpacing={{ base: 'normal', md: 'tight' }}
          >
            Invoice Builder
          </chakra.h1>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

UnauthorizedApp.propTypes = {
  auth: PropTypes.shape({
    authorize: PropTypes.func,
    handleAuthentication: PropTypes.func,
  }),
};

export default UnauthorizedApp;
