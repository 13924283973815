import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 40;
export const formLabel = 'Invoice Header Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Record Code',
    defaultValue: 31,
    readOnly: true,
    hidden: true,
    name: 'invoice_header_record_code',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be 2 digits')
      .max(2, 'Must be 2 digits')
      .required('Required'),
  },
  {
    fieldPosition: 4,
    label: 'Advertiser Name',
    name: 'invoice_header_advertiser_name',
    validation: yup.string().max(25, 'Max length 25 characters').required('Required'),
  },
  {
    fieldPosition: 5,
    label: 'Product Name',
    name: 'invoice_header_product_name',
    validation: yup.string().max(25, 'Max length 25 characters').required('Required'),
  },
  {
    fieldPosition: 6,
    label: 'Invoice Date',
    name: 'invoice_header_invoice_date',
    placeholder: 'YYMMDD',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(6, 'Must be 6 digits')
      .max(6, 'Must be 6 digits')
      .required('Required'),
  },
  {
    fieldPosition: 8,
    label: 'Agency Estimate Code',
    name: 'invoice_header_agency_estimate_code',
    validation: yup.string().max(10, 'Max length 10 characters').required('Required'),
  },
  {
    fieldPosition: 9,
    label: 'Invoice Number',
    name: 'invoice_header_invoice_number',
    validation: yup.string().max(10, 'Max length 10 characters').required('Required'),
  },
  {
    fieldPosition: 10,
    label: 'Broadcast Month',
    name: 'invoice_header_broadcast_month',
    placeholder: 'YYMM',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'Must be 4 digits')
      .max(4, 'Must be 4 digits')
      .required('Required'),
  },
  {
    fieldPosition: 25,
    label: 'Agency Advertiser Code',
    name: 'invoice_header_agency_advertiser_code',
    validation: yup.string().max(8, 'Max length 8 characters').required('Required'),
  },
  {
    fieldPosition: 27,
    label: 'Agency Product Code',
    name: 'invoice_header_agency_product_code',
    validation: yup.string().max(8, 'Max length 8 characters').required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
