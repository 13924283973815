import { Box, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { chakra } from '@chakra-ui/system';
import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../utils/context/UserContext';
import { setAccessToken } from '../../utils/accessToken';

const RedirectToHome = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => history.push('/'), 2000);
  }, [history]);
  return (
    <HStack spacing='4'>
      <Spinner size='xl' />
      <VStack align='left' spacing='0'>
        <Heading fontSize='lg' textTransform='uppercase'>
          Signed In
        </Heading>
        <Text fontSize='xs'>redirecting...</Text>
      </VStack>
    </HStack>
  );
};

const Auth0Callback = () => {
  const { signedIn, user, error, auth, setUserError, setUser } = useContext(UserContext);
  useEffect(() => {
    auth.auth0.parseHash({ hash: window.location.hash }, async (err, authResult) => {
      if (err) {
        setUserError(err.errorDescription);
        return;
      }

      if (!authResult || !authResult.idToken) {
        // not an authentication request
        return;
      }

      if (authResult && authResult.accessToken && authResult.idToken) {
        try {
          setAccessToken(authResult.accessToken);
          setUser(authResult);
        } catch (authError) {
          if (authError.response) {
            setUserError(authError.response.data.error.message);
          }
        }
      }
    });
  }, []);

  let component;

  if (error) {
    component = (
      <Box>
        <Box textAlign='center'>
          <chakra.h1
            px={6}
            fontSize='4xl'
            fontWeight='bold'
            lineHeight={{ base: 'shorter', md: 'none' }}
            letterSpacing={{ base: 'normal', md: 'tight' }}
          >
            Error
          </chakra.h1>
          <chakra.p px={6} my={4} color='gray.600' fontSize='lg'>
            {error}
            <br /> Please{' '}
            <Link
              onClick={() => () => {
                window.location = '/';
              }}
              textDecoration='underline'
            >
              try again.
            </Link>
          </chakra.p>
        </Box>
      </Box>
    );
  } else if (signedIn || user) {
    component = <RedirectToHome />;
  } else {
    component = (
      <HStack spacing='4'>
        <Spinner size='xl' />
        <VStack align='left' spacing='0'>
          <Heading fontSize='lg' textTransform='uppercase'>
            Signing In
          </Heading>
          <Text fontSize='xs'>please wait...</Text>
        </VStack>
      </HStack>
    );
  }

  return (
    <Flex w='full' h='100vh' align='center' justify='center'>
      {component}
    </Flex>
  );
};

export default Auth0Callback;
