import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import App from './App';
import { EnvIndicator } from './components/EnvIndicator';
import './index.css';
import { SambaLoader } from './components/SambaLoader';
import reportWebVitals from './reportWebVitals';
import UnauthorizedApp from './routes/unauthorized';
import Auth0Callback from './routes/unauthorized/Auth0Callback';
import { UserContextProvider, UserContext } from './utils/context/UserContext';
import { delay } from './utils/delay';

const AppComponent = () => {
  const { loadUserInfo, loading, user, setUser } = useContext(UserContext);
  const [configReady, setConfigReady] = useState(false);
  useEffect(() => {
    async function initializeApplication() {
      try {
        await Promise.all([loadUserInfo(), delay(1000)]);
        setConfigReady(true);
      } catch (err) {
        setUser(null);
        setConfigReady(true);
      }
    }

    // Intentionally not returning promise, useEffect does not expect return values
    initializeApplication();
  }, []);

  if (!configReady || loading) return <SambaLoader />;
  return user ? <App /> : <UnauthorizedApp />;
};
const styles = {
  global: (props) => ({
    body: {
      fontFamily: 'body',
      color: mode('gray.900', 'whiteAlpha.900')(props),
      bg: mode('#F2F5FA', 'dark.900')(props),
      lineHeight: 'base',
    },
  }),
};
const colors = {
  plum: {
    50: '#e8e8ff',
    100: '#bdbcf9',
    200: '#605CE7',
    300: '#6763e8',
    400: '#3d37e1',
    500: '#605CE7',
    600: '#605CE7',
    700: '#121070',
    800: '#080946',
    900: '#03021c',
  },
  // hack for chakra progress
  // to show same color for progress dark/light mode
  fixedplum: {
    200: '#605CE7',
    500: '#605CE7',
  },
  sambared: {
    600: '#ff8188',
    300: '#ff8188',
    200: '#F75C65',
    500: '#F75C65',
  },
  dark: {
    500: '#171925', // figma: dark light
    600: '#0E111D', // figma: dark medium
    900: '#050511', // figma: darkest
  },
  fonts: {
    heading: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    body: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    mono: '"SFMono-Regular", "Roboto Mono", "Menlo", monospace',
  },
};
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const myTheme = extendTheme({ styles, colors, config });
const env = process.env.REACT_APP_ENV;

ReactDOM.render(
  <ChakraProvider resetCSS theme={myTheme}>
    <UserContextProvider>
      <Router>
        <Switch>
          <Route path='/callback'>
            <Auth0Callback />
          </Route>
          <Route path='/'>
            {env !== 'production' && <EnvIndicator env={env} />}
            <AppComponent />
          </Route>
        </Switch>
      </Router>
    </UserContextProvider>
  </ChakraProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
