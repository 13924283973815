import get from 'lodash/get';
import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import { splitEveryNCharacters } from '../../../../utils/strings';

const MinimizedDetail = ({ record, onClickEdit }) => {
  const runDetails = useMemo(() => {
    return {
      runDate: splitEveryNCharacters(record.broadcast_run_date, 2).join('/'),
      runTime: splitEveryNCharacters(record.broadcast_time_of_day, 2).join(':'),
    };
  }, [record]);
  return (
    <Box py={5} px={10} backgroundColor={'#b8b8b8'} borderColor={'black'} borderTopWidth={1}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <p>
          Run details: {get(runDetails, 'runDate')} at {get(runDetails, 'runTime')}
        </p>
        <Button onClick={onClickEdit}>Edit</Button>
      </Box>
    </Box>
  );
};

export default MinimizedDetail;
