import get from 'lodash/get';
import React from 'react';
import { Box, HStack, Text, Input as ChakraInput, Select as ChakraSelect } from '@chakra-ui/react';

const Input = ({
  error = null,
  hidden = false,
  readOnly = false,
  defaultValue,
  placeholder,
  label,
  name,
  register,
  options = [],
  ...props
}) => {
  // If the field is marked hidden, hide and use the default value
  if (hidden) {
    return <ChakraInput hidden defaultValue={defaultValue} placeholder={placeholder} {...register(name)} />;
  }
  return (
    <Box pt='3'>
      <HStack mb='2' fontSize='xs' color={error && 'red.500'} justify='space-between'>
        <Text>{label}</Text>
        {error && get(error, 'message') ? (
          <Text mt={2} fontSize={'xs'} color={'red.500'}>
            {get(error, 'message')}
          </Text>
        ) : null}
      </HStack>
      {options && options.length ? (
        <ChakraSelect
          placeholder='Select option'
          size='lg'
          defaultValue={defaultValue}
          isReadOnly={readOnly}
          isInvalid={error}
          {...register(name)}
          {...props}
        >
          {options.map((option) => {
            const extraProps = {};
            if (option === defaultValue) {
              extraProps.selected = true;
            }
            return (
              <option value={option} {...extraProps}>
                {option}
              </option>
            );
          })}
        </ChakraSelect>
      ) : (
        <ChakraInput
          size='lg'
          defaultValue={defaultValue}
          isReadOnly={readOnly}
          placeholder={placeholder}
          isInvalid={error}
          {...register(name)}
          {...props}
        />
      )}
    </Box>
  );
};

export default Input;
