import get from 'lodash/get';
import React from 'react';
import { Box } from '@chakra-ui/layout';

import Input from '../../../components/Input';

const RecordGroup = ({ errors, register, label, fields }) => {
  return (
    <Box pt={10}>
      <h2>{label}</h2>
      {fields.map((field) => {
        return (
          <Input
            key={field.name}
            options={field.options}
            error={get(errors, field.name, false)}
            register={register}
            defaultValue={field.defaultValue}
            readOnly={field.readOnly}
            hidden={field.hidden}
            name={field.name}
            label={field.label}
            placeholder={get(field, 'placeholder')}
          />
        );
      })}
    </Box>
  );
};

export default RecordGroup;
