import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 12;
export const formLabel = 'Station Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Record Code',
    defaultValue: 22,
    name: 'station_record_code',
    readOnly: true,
    hidden: true,
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be 2 digits')
      .max(2, 'Must be 2 digits')
      .required('Required'),
  },
  {
    fieldPosition: 2,
    label: 'Call Letters',
    name: 'station_call_letters',
    validation: yup.string().max(4, 'Max length 4 characters').required('Required'),
  },
  {
    fieldPosition: 3,
    label: 'Media Type',
    name: 'station_media_type',
    defaultValue: 'T',
    readOnly: true,
    validation: yup.string().required('Required'),
    options: ['T', 'TV', 'R', 'LC', 'NC', 'N', 'X'],
  },
  {
    fieldPosition: 4,
    label: 'Band',
    name: 'station_band',
    defaultValue: 'DV',
    readOnly: true,
    validation: yup.string().required('Required'),
    options: ['AM', 'FM', 'X', 'TV', 'SM', 'DV'],
  },
  {
    fieldPosition: 5,
    label: 'Station Name',
    name: 'station_name',
    defaultValue: 'Samba TV',
    readOnly: true,
    validation: yup.string().max(30).required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
