import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/layout';

import { Error404 } from './components/Error404';
import Form from './routes/authorized/form/Form';
import { FormContextProvider } from './utils/context/FormContext';

const App = () => {
  const signedIn = true;
  return signedIn ? (
    <Box>
      <Flex>
        <Box id='body' flexGrow='1' h='100vh' overflow='auto'>
          <Switch>
            <Route
              path='/'
              exact
              render={() => (
                <FormContextProvider>
                  <Form />
                </FormContextProvider>
              )}
            />
            <Route path='*' exact={true} component={Error404} />
          </Switch>
        </Box>
      </Flex>
    </Box>
  ) : (
    <></>
  );
};

export default App;
