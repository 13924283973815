import React from 'react';

import { fields, schema } from './schema';
import RecordForm from '../RecordForm';
import { RECORD_TYPES } from '../schema';

const AgencyRecord = ({ onPressNext, onPressPrev }) => {
  return (
    <RecordForm
      fields={fields}
      recordType={RECORD_TYPES.AGENCY}
      onPressPrev={onPressPrev}
      onPressNext={onPressNext}
      schema={schema}
    />
  );
};

export default AgencyRecord;
