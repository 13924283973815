import React from 'react';
import { Box, HStack } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import { BiCodeAlt } from 'react-icons/bi';

export const EnvIndicator = ({ env }) => (
  <Box
    position='fixed'
    bgGradient='linear(to-r, pink.500, purple.500)'
    fontFamily='Roboto Mono'
    fontWeight='100'
    color='white'
    px='4'
    pt='3'
    pb='1'
    mt='-2'
    ml='-1'
    borderRadius='md'
    left='50%'
    transform='translateX(-50%)'
    zIndex='10'
    shadow='xl'
    textTransform='uppercase'
  >
    <HStack>
      <BiCodeAlt />
      <chakra.div fontSize='11px' whiteSpace='nowrap'>
        {env} environment
      </chakra.div>
    </HStack>
  </Box>
);
