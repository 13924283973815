import React, { useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/layout';
import { yupResolver } from '@hookform/resolvers/yup';

import RecordGroup from './RecordGroup';
import { recordToLabels } from './schema';
import { FormContext } from '../../../utils/context/FormContext';

const RecordForm = ({ onPressNext, onPressPrev, fields, recordType, schema, submitText = 'Next' }) => {
  const { onPressNext: onFormSuccess, getFormValuesByRecordType } = useContext(FormContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: getFormValuesByRecordType(recordType),
  });

  const onSubmit = (values) => {
    const newFormValues = onFormSuccess(recordType, values);
    onPressNext(newFormValues);
  };

  return (
    <Box width={'500px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RecordGroup errors={errors} register={register} fields={fields} label={recordToLabels[recordType]} />
        {onPressPrev ? (
          <Button mt={4} onClick={onPressPrev}>
            Prev
          </Button>
        ) : null}
        <Button mt={4} isLoading={isSubmitting} type='submit'>
          {submitText}
        </Button>
      </form>
    </Box>
  );
};

export default RecordForm;
