import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 6;
export const formLabel = 'Payee Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Record Code',
    defaultValue: 23,
    readOnly: true,
    hidden: true,
    name: 'payee_record_code',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be 2 digits')
      .max(2, 'Must be 2 digits')
      .required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
