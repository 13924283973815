import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router';

export const Error404 = () => {
  const history = useHistory();

  return (
    <Flex h='full' justify='center' align='center'>
      <VStack>
        <Heading fontSize='280px'>404</Heading>
        <Box align='center'>
          <Text mt='2' fontSize='xl'>
            The page you were looking for doesn't exist. Please use the side menu or check your URL.
          </Text>

          <Button variant='outline' colorScheme='plum' mt='10' onClick={() => history.push('/')}>
            Back Home
          </Button>
        </Box>
      </VStack>
    </Flex>
  );
};
