import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 3;
export const formLabel = 'Transmission Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Record Code',
    defaultValue: 12,
    readOnly: true,
    hidden: true,
    name: 'transmission_total_record_code',
    validation: yup.string().min(2, 'Must be 2 digits').max(2, 'Must be 2 digits').required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
