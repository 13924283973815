import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 7;
export const formLabel = 'Agency Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Agency Record Code',
    defaultValue: 21,
    readOnly: true,
    hidden: true,
    name: 'agency_record_code',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be 2 digits')
      .max(2, 'Must be 2 digits')
      .required('Required'),
  },
  {
    fieldPosition: 2,
    label: 'Agency ID',
    name: 'agency_id',
    validation: yup.string().required('Required').max(8, 'Mex length 8 characters'),
  },
  {
    fieldPosition: 3,
    label: 'Agency Name',
    name: 'agency_name',
    validation: yup.string().required('Required').max(25, 'Mex length 25 characters'),
  },
  {
    fieldPosition: 4,
    label: 'Address Line 1',
    name: 'agency_address_line_1',
    validation: yup.string().required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
