import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 26;
export const formLabel = 'Broadcast Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Record Code',
    defaultValue: 51,
    readOnly: true,
    hidden: true,
    name: 'broadcast_record_code',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(2, 'Must be 2 digits')
      .max(2, 'Must be 2 digits')
      .required('Required'),
  },
  {
    fieldPosition: 2,
    label: 'Run Code',
    defaultValue: 'Y',
    name: 'broadcast_run_code',
    validation: yup.string().required('Required'),
    options: ['Y', 'N'],
  },
  {
    fieldPosition: 3,
    label: 'Run Date',
    name: 'broadcast_run_date',
    placeholder: 'YYMMDD',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(6, 'Must be 6 digits')
      .max(6, 'Must be 6 digits')
      .required('Required'),
    // TODO: Verify is valid date, formatted = YYMMDD
  },
  {
    fieldPosition: 5,
    label: 'Time of Day',
    name: 'broadcast_time_of_day',
    placeholder: 'HHMM',
    validation: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(4, 'Must be 4 digits')
      .max(4, 'Must be 4 digits')
      .required('Required'),
    // TODO: Verify valid date, in HHMM, military time
  },
  {
    fieldPosition: 6,
    label: 'Type (Spot Length)',
    name: 'broadcast_type_spot_length',
    validation: yup.string().max(3, 'Max length 3 digits').required('Required'),
    // TODO: Seconds - for minutes 3=30, 6=60, 12=20?
  },
  {
    fieldPosition: 8,
    label: 'Rate',
    defaultValue: 'CPM',
    name: 'broadcast_rate',
    validation: yup.string().max(10, 'Max length 10 characters').required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
