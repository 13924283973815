import { formLabel as agencyLabel, fields as agencyFields, totalFields as agencyTotalFields } from './agency/schema';
import {
  formLabel as broadcastLabel,
  fields as broadcastFields,
  totalFields as broadCastTotalFields,
} from './broadcast-detail/schema';
import {
  formLabel as invoiceHeaderLabel,
  fields as invoiceHeaderFields,
  totalFields as invoiceHeaderTotalFields,
} from './invoice-header/schema';
import {
  formLabel as invoiceTotalLabel,
  fields as invoiceTotalFields,
  totalFields as invoiceTotalTotalFields,
} from './invoice-total/schema';
import { formLabel as payeeLabel, fields as payeeFields, totalFields as payeeTotalFields } from './payee/schema';
import {
  formLabel as transmissionLabel,
  fields as transmissionFields,
  totalFields as transmissionTotalFields,
} from './transmission-total/schema';
import {
  formLabel as stationLabel,
  fields as stationFields,
  totalFields as stationTotalFields,
} from './station/schema';

export const RECORD_TYPES = {
  AGENCY: 'agency',
  BROADCAST: 'broadcast',
  INVOICE_HEADER: 'invoiceHeader',
  INVOICE_TOTAL: 'invoiceTotal',
  PAYEE: 'payee',
  TRANSMISSION_TOTAL: 'transmissionTotal',
  STATION: 'station',
};

export const schemas = {
  [RECORD_TYPES.AGENCY]: agencyFields,
  [RECORD_TYPES.BROADCAST]: broadcastFields,
  [RECORD_TYPES.INVOICE_HEADER]: invoiceHeaderFields,
  [RECORD_TYPES.INVOICE_TOTAL]: invoiceTotalFields,
  [RECORD_TYPES.PAYEE]: payeeFields,
  [RECORD_TYPES.TRANSMISSION_TOTAL]: transmissionFields,
  [RECORD_TYPES.STATION]: stationFields,
};

export const ORDERED_RECORD_TYPES = [
  RECORD_TYPES.AGENCY,
  RECORD_TYPES.STATION,
  RECORD_TYPES.PAYEE,
  RECORD_TYPES.INVOICE_HEADER,
  RECORD_TYPES.BROADCAST,
  RECORD_TYPES.INVOICE_TOTAL,
  RECORD_TYPES.TRANSMISSION_TOTAL,
];

export const recordTotalFields = {
  [RECORD_TYPES.AGENCY]: agencyTotalFields,
  [RECORD_TYPES.BROADCAST]: broadCastTotalFields,
  [RECORD_TYPES.INVOICE_HEADER]: invoiceHeaderTotalFields,
  [RECORD_TYPES.INVOICE_TOTAL]: invoiceTotalTotalFields,
  [RECORD_TYPES.PAYEE]: payeeTotalFields,
  [RECORD_TYPES.TRANSMISSION_TOTAL]: transmissionTotalFields,
  [RECORD_TYPES.STATION]: stationTotalFields,
};

export const recordToLabels = {
  [RECORD_TYPES.AGENCY]: agencyLabel,
  [RECORD_TYPES.BROADCAST]: broadcastLabel,
  [RECORD_TYPES.INVOICE_HEADER]: invoiceHeaderLabel,
  [RECORD_TYPES.INVOICE_TOTAL]: invoiceTotalLabel,
  [RECORD_TYPES.PAYEE]: payeeLabel,
  [RECORD_TYPES.TRANSMISSION_TOTAL]: transmissionLabel,
  [RECORD_TYPES.STATION]: stationLabel,
};
