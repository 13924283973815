import React, { useCallback, useContext, useState } from 'react';
import { Box } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import MinimizedDetail from './MinimizedDetail';
import BroadcastForm from './BroadcastForm';
import { RECORD_TYPES } from '../schema';
import { FormContext } from '../../../../utils/context/FormContext';

const BroadcastDetailRecord = ({ onPressNext, onPressPrev }) => {
  const { onPressNext: onFormSuccess } = useContext(FormContext);
  const [broadcastRecords, setBroadcastRecords] = useState([]);
  const [activeRecord, setActiveRecord] = useState(null);

  const onAddRecord = useCallback(
    (values) => {
      setBroadcastRecords([...broadcastRecords, values]);
    },
    [activeRecord, broadcastRecords],
  );

  const onUpdateRecord = useCallback(
    (values, index) => {
      setBroadcastRecords([...broadcastRecords.slice(0, index), values, ...broadcastRecords.slice(index + 1)]);
    },
    [broadcastRecords],
  );

  const onRemoveRecord = useCallback(
    (index) => {
      setBroadcastRecords([...broadcastRecords.slice(0, index), ...broadcastRecords.slice(index + 1)]);
    },
    [broadcastRecords],
  );

  const onPressNextSubmit = useCallback(() => {
    onFormSuccess(RECORD_TYPES.BROADCAST, broadcastRecords);
    onPressNext();
  }, [broadcastRecords, onPressNext]);

  return (
    <Box pt={10}>
      {broadcastRecords && broadcastRecords.length ? (
        <Box m={3} p={5} borderWidth={1} borderColor={'black'} backgroundColor={'lightgray'}>
          <h2>Broadcast Detail Records</h2>
          {broadcastRecords.map((record, recordCount) => {
            if (activeRecord === recordCount) {
              return (
                <Box p={3} borderColor={'black'} borderTopWidth={1}>
                  <BroadcastForm
                    initialValues={record}
                    onSubmit={(values) => {
                      onUpdateRecord(values, recordCount);
                      setActiveRecord(null);
                    }}
                    onRemove={() => {
                      onRemoveRecord(recordCount);
                      setActiveRecord(null);
                    }}
                  />
                </Box>
              );
            }
            return <MinimizedDetail record={record} onClickEdit={() => setActiveRecord(recordCount)} />;
          })}
        </Box>
      ) : null}
      <BroadcastForm headerLabel={'Add New Record'} onSubmit={onAddRecord} submitLabel={'Save'} />
      <Button mt={4} onClick={onPressPrev}>
        Prev
      </Button>
      <Button mt={4} disabled={!broadcastRecords || !broadcastRecords.length} onClick={onPressNextSubmit}>
        Next
      </Button>
    </Box>
  );
};

export default BroadcastDetailRecord;
