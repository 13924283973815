import * as yup from 'yup';
import { combineFieldValidations } from '../../../../utils/validation';

export const totalFields = 15;
export const formLabel = 'Invoice Total Record';
export const fields = [
  {
    fieldPosition: 1,
    label: 'Record Code',
    defaultValue: 34,
    readOnly: true,
    hidden: true,
    name: 'invoice_total_record_code',
    validation: yup.string().min(2, 'Must be 2 digits').max(2, 'Must be 2 digits').required('Required'),
  },
  {
    fieldPosition: 3,
    label: 'Actual Gross Billing',
    name: 'invoice_total_actual_gross_billing',
    validation: yup.string().max(11, 'Max length 11 characters').required('Required'),
  },
  {
    fieldPosition: 4,
    label: 'Agency Commission',
    name: 'invoice_total_agency_commission',
    validation: yup.string().max(11, 'Max length 11 characters').required('Required'),
  },
  {
    fieldPosition: 5,
    label: 'Net Due',
    name: 'invoice_total_net_due',
    validation: yup.string().max(11, 'Max length 11 characters').required('Required'),
  },
];

export const schema = combineFieldValidations(fields);
